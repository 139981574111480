import { ENV_CONSTANTS } from "../constants/env.const";
import routes from "../constants/routes";

const setCurrentUrl = (url: string) => {
  if (url !== routes.CHECKOUT_SUCCESS) {
    localStorage.setItem("previousPage", url);
  }
};

export const getPreviousUrl = () => {
  const previousUrl = localStorage.getItem("previousPage");

  if (previousUrl && previousUrl !== routes.HOME) {
    return previousUrl;
  }

  if (ENV_CONSTANTS.FEATURE_PRIVATE_WORKSPACE_ENABLED) {
    return routes.WORKSPACES;
  }

  return routes.HOME;
};

export const formattedShortUrl = (pathname: string) => {
  return `/${pathname.split("/").filter((part) => part)[0]}`;
};

export default setCurrentUrl;
