import {
  SignupButton,
  SignupContainer,
  SignupContent,
  SignupTitle,
} from "@/app/(body)/home.styles";
import { ENV_CONSTANTS } from "@/common/constants/env.const";
import routes from "@/common/constants/routes";
import setCurrentUrl from "@/common/utils/setCurrentUrl";
import theme from "@/styles/theme";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { usePathname, useRouter } from "next/navigation";

export const Signup = () => {
  const { t } = useTranslation("home");
  const pathName = usePathname();
  const router = useRouter();

  const handleClick = () => {
    setCurrentUrl(pathName);
    router.push(routes.REGISTER);
  };
  return (
    <SignupContainer>
      <SignupContent>
        <SignupTitle variant="displayM">{t("home.signup.title")}</SignupTitle>

        <Typography
          variant="bodyXL"
          sx={{
            paddingTop: theme.spacing(4),
            color: "rgba(0, 0, 0, 0.58)",
          }}
        >
          {t("home.signup.sub.title", {
            trial: ENV_CONSTANTS.STRIPE_TRIAL_PERIOD,
          })}
        </Typography>
        <SignupButton variant="outlinedSquared" onClick={handleClick}>
          {t("home.signup.btn.text", {
            trial: ENV_CONSTANTS.STRIPE_TRIAL_PERIOD,
          })}
        </SignupButton>
        <Box
          display="flex"
          gap={1.5}
          alignItems="center"
          justifyContent="center"
          paddingTop={2}
        >
          <Typography variant="bodyM" color="textPrimary">
            {t("home.header.text.noCreditCardReq")}
          </Typography>
        </Box>
      </SignupContent>
    </SignupContainer>
  );
};
