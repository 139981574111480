import { useTranslation } from "react-i18next";
import {
  HeaderButton,
  HeaderInfoSubTitle,
  HeaderInfoTitle,
} from "@/app/(body)/home.styles";
import setCurrentUrl from "@/common/utils/setCurrentUrl";
import { usePathname, useRouter } from "next/navigation";
import { Trans } from "next-i18next";
import { Box, Typography } from "@mui/material";
import { CreditCardOffRounded } from "@mui/icons-material";
import routes from "@/common/constants/routes";

const HeaderInfo = () => {
  const { t } = useTranslation("home");
  const pathName = usePathname();
  const router = useRouter();

  const handleClick = () => {
    setCurrentUrl(pathName);
    router.push(routes.REGISTER);
  };

  return (
    <>
      <HeaderInfoTitle variant="displayLA">
        <Trans
          t={t}
          i18nKey="home.header.title"
          components={{
            br: <br />,
          }}
        />
      </HeaderInfoTitle>

      <HeaderInfoSubTitle variant="bodyXL" color="textSecondary">
        {t("home.header.sub.title")}
      </HeaderInfoSubTitle>

      <HeaderButton variant="outlinedSquared" onClick={handleClick}>
        {t("home.header.btn.text")}
      </HeaderButton>

      <Box
        display="flex"
        gap={1.5}
        alignItems="center"
        alignSelf="baseline"
        paddingTop={3}
      >
        <CreditCardOffRounded fontSize="small" sx={{ color: "#727375" }} />
        <Typography variant="bodyM" color="textPrimary">
          {t("home.header.text.noCreditCardReq")}
        </Typography>
      </Box>
    </>
  );
};

export default HeaderInfo;
