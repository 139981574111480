"use client";

import routes from "@/common/constants/routes";
import { useAppSelector } from "@/store";
import { selectReceiverConfirmedOrLoading } from "@/store/slices/receiver.slice";
import theme, { withTransientProps } from "@/styles/theme";
import styled from "@emotion/styled";
import Image from "next/image";
import { usePathname } from "next/navigation";
import { useMemo } from "react";

interface ILogoProps {
  $fixToRight: boolean;
  $position?: string;
  $pathname?: string;
}
const Logo = styled("a", withTransientProps)<ILogoProps>`
  && {
    z-index: 13;
    display: block;
    position: fixed;
    bottom: 50px;

    ${(p) =>
      p.$fixToRight
        ? `
        right: 0;
        transform: rotate(-180deg);
      `
        : `
        left: 0;
      `}

    ${(p) =>
      p.$pathname === routes.HOME &&
      `
        ${theme.breakpoints.down("sm")} {
          top: 470px;
          position: absolute;
          right: 0;
          bottom: auto;
          left: auto;
          transform: rotate(-180deg);
        `}

${(p) =>
      p.$pathname !== routes.HOME &&
      `
        ${theme.breakpoints.down("sm")} {
          display: none;
        `}
  }
`;

const PeachLogo = () => {
  const pathName = usePathname();
  const isReceiverConfirmedOrLoading = useAppSelector(
    selectReceiverConfirmedOrLoading,
  );

  const isLogoStickedToRight = useMemo(() => {
    return pathName === "/user/receiver" && isReceiverConfirmedOrLoading;
  }, [pathName, isReceiverConfirmedOrLoading]);

  const srcImage =
    pathName === routes.REGISTER
      ? "/img/logoPeach.svg"
      : "/img/peach-left-b.svg";

  return (
    <Logo
      $fixToRight={isLogoStickedToRight}
      $pathname={pathName}
      href="https://www.peach.me/"
      target="_blank"
      data-testid="peach-logo-link"
      data-analytics="peach-logo-link"
    >
      <Image
        src={srcImage}
        width={46}
        height={92}
        alt="Peach Logo"
        priority
        quality={10}
        data-testid="peach-logo"
        data-analytics="peach-logo"
      />
    </Logo>
  );
};

export default PeachLogo;
