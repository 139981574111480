import {
  StyledTab,
  StyledTabImage,
  StyledTabs,
  TabContainer,
  TabPanelStyled,
} from "@/app/(body)/home.styles";
import { TabContext } from "@mui/lab";
import { useState } from "react";
import { useTranslation } from "next-i18next";
import { Box, Typography } from "@mui/material";

const TabbedContent = () => {
  const [value, setValue] = useState("1");
  const { t } = useTranslation("home");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box paddingBottom={15}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box>
          <Typography variant="displayM">{t("home.tabs.title")}</Typography>
        </Box>
        <Box paddingTop={2} paddingBottom={5}>
          <Typography variant="titleMA" color="textSecondary">
            {t("home.tabs.subtitle")}
          </Typography>
        </Box>
      </Box>
      <TabContext value={value}>
        <TabContainer>
          <StyledTabs
            onChange={handleChange}
            aria-label="lab API tabs example"
            value={value}
          >
            <StyledTab
              disableRipple
              label={t("home.tab.label.one")}
              value="1"
            />
            <StyledTab
              disableRipple
              label={t("home.tab.label.two")}
              value="2"
            />
            <StyledTab
              disableRipple
              label={t("home.tab.label.three")}
              value="3"
            />
            <StyledTab
              disableRipple
              label={t("home.tab.label.four")}
              value="4"
            />
          </StyledTabs>
        </TabContainer>
        <TabPanelStyled value="1">
          <StyledTabImage
            src="/img/pages/landing/tabbedSection/workspace-send-files.png"
            width="1156"
            height="679"
            alt={t("home.alt.text.tab.one")}
          />
        </TabPanelStyled>
        <TabPanelStyled value="2">
          <StyledTabImage
            src="/img/pages/landing/tabbedSection/creative-qa.png"
            width="1156"
            height="678"
            alt={t("home.alt.text.tab.two")}
          />
        </TabPanelStyled>
        <TabPanelStyled value="3">
          <StyledTabImage
            src="/img/pages/landing/tabbedSection/safe-zones.png"
            width="1156"
            height="678"
            alt={t("home.alt.text.tab.three")}
          />
        </TabPanelStyled>

        <TabPanelStyled value="4">
          <StyledTabImage
            src="/img/pages/landing/tabbedSection/workspace.png"
            width="1156"
            height="678"
            alt={t("home.alt.text.tab.four")}
          />
        </TabPanelStyled>
      </TabContext>
    </Box>
  );
};

export default TabbedContent;
