import { HomeHeaderStyledGrid, StyledImage } from "@/app/(body)/home.styles";
import { Box, Grid } from "@mui/material";
import HeaderInfo from "@/components/home/HeaderInfo";
import { useTranslation } from "next-i18next";

export const Header = () => {
  const { t } = useTranslation("home");

  return (
    <Grid container flex={1}>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <HeaderInfo />
      </Grid>

      <HomeHeaderStyledGrid item xs={12} sm={12} md={6}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <StyledImage
            src="/img/pages/landing/main-visual.png"
            width={684}
            height={541}
            alt={t("home.alt.text.header")}
            priority
          />
        </Box>
      </HomeHeaderStyledGrid>
    </Grid>
  );
};
