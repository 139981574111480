import {
  StyledImage,
  WorkflowContainer,
  WorkflowFeatureContainer,
  WorkflowFeatureGridBlockFive,
  WorkflowFeatureGridBlockFour,
  WorkflowFeatureGridBlockOne,
  WorkflowFeatureGridBlockThree,
  WorkflowFeatureGridBlockTwo,
  WorkflowFeatureGridContainer,
  WorkflowFeatureGridLeft,
  WorkflowFeatureGridRight,
  WorkflowFeatureInfo,
  WorkflowGridSubTitle,
  WorkflowGridTitle,
  WorkflowGridTitleContainer,
  WorkflowLeft,
  WorkflowRight,
  WorkflowSubTitle,
  WorkflowTitle,
} from "@/app/(body)/home.styles";
import theme from "@/styles/theme";
import { Box, Grid, Typography } from "@mui/material";
import { Trans, useTranslation } from "next-i18next";

export const Workflow = () => {
  const { t } = useTranslation("home");
  return (
    <WorkflowContainer>
      <WorkflowTitle variant="displayLA">
        <Trans
          t={t}
          i18nKey="home.workflow.title"
          components={{
            br: <br />,
          }}
        />
      </WorkflowTitle>

      <WorkflowSubTitle variant="titleMA">
        {t("home.workflow.sub.title")}
      </WorkflowSubTitle>

      <WorkflowFeatureContainer container>
        <WorkflowLeft item md={6}>
          <WorkflowFeatureInfo $paddingRight={true}>
            <Typography variant="hLA" sx={{ color: "#ffffff" }}>
              {t("home.workflow.feature.one.title")}
            </Typography>

            <Typography
              variant="bodyXL"
              sx={{
                color: "#D1D1D2",
                marginTop: theme.spacing(2),
              }}
            >
              {t("home.workflow.feature.one.sub.title")}
            </Typography>

            <Typography
              variant="bodyLA"
              sx={{
                color: "#C5C6C7",
                marginTop: theme.spacing(2.5),
              }}
            >
              {t("home.workflow.feature.one.text")}
            </Typography>
          </WorkflowFeatureInfo>
        </WorkflowLeft>

        <WorkflowRight
          item
          md={6}
          alignItems="center"
          justifyContent="center"
          display="flex"
        >
          <StyledImage
            src="/img/pages/landing/featuresSection/creative-qa.png"
            alt={t("home.alt.text.workflow.one")}
            width={480}
            height={350}
            priority
          />
        </WorkflowRight>
      </WorkflowFeatureContainer>

      <WorkflowFeatureContainer container>
        <WorkflowRight
          item
          md={6}
          alignItems="center"
          justifyContent="center"
          display="flex"
        >
          <StyledImage
            src="/img/pages/landing/featuresSection/file-transfer.png"
            alt={t("home.alt.text.workflow.two")}
            width={600}
            height={440}
            priority
          />
        </WorkflowRight>
        <WorkflowLeft item md={6}>
          <WorkflowFeatureInfo>
            <Typography variant="hLA" sx={{ color: "#ffffff" }}>
              {t("home.workflow.feature.two.title")}
            </Typography>

            <Typography
              variant="bodyXL"
              sx={{
                color: "#D1D1D2",
                marginTop: theme.spacing(2),
              }}
            >
              {t("home.workflow.feature.two.sub.title")}
            </Typography>

            <Typography
              variant="bodyLA"
              sx={{
                color: "#C5C6C7",
                marginTop: theme.spacing(2.5),
              }}
            >
              {t("home.workflow.feature.two.text")}
            </Typography>
          </WorkflowFeatureInfo>
        </WorkflowLeft>
      </WorkflowFeatureContainer>

      <WorkflowFeatureContainer container>
        <WorkflowLeft item md={6}>
          <WorkflowFeatureInfo $paddingRight={true}>
            <Typography variant="hLA" sx={{ color: "#ffffff" }}>
              {t("home.workflow.feature.three.title")}
            </Typography>

            <Typography
              variant="bodyXL"
              sx={{
                color: "#D1D1D2",
                marginTop: theme.spacing(2),
              }}
            >
              {t("home.workflow.feature.three.sub.title")}
            </Typography>

            <Typography
              variant="bodyLA"
              sx={{
                color: "#C5C6C7",
                marginTop: theme.spacing(2.5),
              }}
            >
              {t("home.workflow.feature.three.text")}
            </Typography>
          </WorkflowFeatureInfo>
        </WorkflowLeft>

        <WorkflowRight
          item
          md={6}
          alignItems="center"
          justifyContent="center"
          display="flex"
        >
          <StyledImage
            src="/img/pages/landing/featuresSection/safe-zones.png"
            alt={t("home.alt.text.workflow.three")}
            width={540}
            height={403}
            priority
          />
        </WorkflowRight>
      </WorkflowFeatureContainer>

      <WorkflowFeatureGridContainer>
        <WorkflowFeatureGridLeft>
          <WorkflowFeatureGridBlockOne>
            <Grid container spacing={0} justifyContent="space-between" gap={7}>
              <Grid
                item
                md={7}
                justifyContent="center"
                alignItems="center"
                display="flex"
              >
                <WorkflowGridTitleContainer>
                  <WorkflowGridTitle variant="hLA">
                    {t("home.workflow.feature.grid.block.one.title")}
                  </WorkflowGridTitle>

                  <WorkflowGridSubTitle variant="bodyXL">
                    {t("home.workflow.feature.grid.block.one.sub.title")}
                  </WorkflowGridSubTitle>
                </WorkflowGridTitleContainer>
              </Grid>

              <Grid item md={4}>
                <StyledImage
                  src="/img/pages/landing/grid/shareable-links.svg"
                  width={257}
                  height={267}
                  alt={t("home.alt.text.workflow.grid.one")}
                />
              </Grid>
            </Grid>
          </WorkflowFeatureGridBlockOne>
          <WorkflowFeatureGridBlockTwo>
            <StyledImage
              src="/img/pages/landing/grid/malware-and-virus-scans.svg"
              width={243}
              height={242}
              alt={t("home.alt.text.workflow.grid.two")}
            />
            <WorkflowGridTitle
              variant="hLA"
              sx={{ marginTop: theme.spacing(3) }}
            >
              {t("home.workflow.feature.grid.block.two.title")}
            </WorkflowGridTitle>

            <WorkflowGridSubTitle variant="bodyXL">
              {t("home.workflow.feature.grid.block.two.sub.title")}
            </WorkflowGridSubTitle>
          </WorkflowFeatureGridBlockTwo>
          <WorkflowFeatureGridBlockThree>
            <Box>
              <WorkflowGridTitle variant="hLA">
                {t("home.workflow.feature.grid.block.three.title")}
              </WorkflowGridTitle>

              <WorkflowGridSubTitle
                variant="bodyXL"
                sx={{ marginBottom: theme.spacing(3) }}
              >
                {t("home.workflow.feature.grid.block.three.sub.title")}
              </WorkflowGridSubTitle>
            </Box>
            <Box>
              <StyledImage
                src="/img/pages/landing/grid/security.png"
                width={191}
                height={246}
                alt={t("home.alt.text.workflow.grid.three")}
              />
            </Box>
          </WorkflowFeatureGridBlockThree>
        </WorkflowFeatureGridLeft>

        <WorkflowFeatureGridRight>
          <WorkflowFeatureGridBlockFour>
            <WorkflowGridTitle variant="hLA">
              {t("home.workflow.feature.grid.block.four.title")}
            </WorkflowGridTitle>

            <WorkflowGridSubTitle
              variant="bodyXL"
              sx={{ marginBottom: theme.spacing(3) }}
            >
              {t("home.workflow.feature.grid.block.four.sub.title")}
            </WorkflowGridSubTitle>
            <StyledImage
              src="/img/pages/landing/grid/asset-preview.svg"
              width={302}
              height={330}
              alt={t("home.alt.text.workflow.grid.four")}
            />
          </WorkflowFeatureGridBlockFour>

          <WorkflowFeatureGridBlockFive>
            <WorkflowGridTitle variant="hLA">
              {t("home.workflow.feature.grid.block.five.title")}
            </WorkflowGridTitle>

            <WorkflowGridSubTitle
              variant="bodyXL"
              sx={{ marginBottom: theme.spacing(3) }}
            >
              {t("home.workflow.feature.grid.block.five.sub.title")}
            </WorkflowGridSubTitle>
            <StyledImage
              src="/img/pages/landing/grid/password-protection.svg"
              width={302}
              height={73}
              alt={t("home.alt.text.workflow.grid.five")}
            />
          </WorkflowFeatureGridBlockFive>
        </WorkflowFeatureGridRight>
      </WorkflowFeatureGridContainer>
    </WorkflowContainer>
  );
};
