import {
  AdvertiserImageContainer,
  AdvertiserLogosContainer,
  AdvertisingStyledSubTitle,
  AdvertisingTitleContainer,
  AdvertisingTitleOne,
  StyledImageAdvertising,
} from "@/app/(body)/home.styles";

import { useTranslation } from "next-i18next";

const ADVERTISER_LOGOS = [
  {
    alt: "ogilvy",
    src: "/img/pages/landing/clientLogos/ogilvy.svg",
    width: 125,
    height: 40,
    display: "mobile",
  },
  {
    alt: "sky",
    src: "/img/pages/landing/clientLogos/sky.svg",
    width: 125,
    height: 40,
    display: "mobile",
  },
  {
    alt: "universal",
    src: "/img/pages/landing/clientLogos/universal.svg",
    width: 125,
    height: 40,
    display: "mobile",
  },
  {
    alt: "nestle",
    src: "/img/pages/landing/clientLogos/nestle.svg",
    width: 125,
    height: 40,
    display: "mobile",
  },
  {
    alt: "coca-cola",
    src: "/img/pages/landing/clientLogos/coca-cola.svg",
    width: 125,
    height: 40,
    display: "mobile",
  },
  {
    alt: "johnson and johnson",
    src: "/img/pages/landing/clientLogos/johnsonandjohnson.svg",
    width: 250,
    height: 40,
    display: "mobile",
  },

  {
    alt: "philips",
    src: "/img/pages/landing/clientLogos/philips.svg",
    width: 125,
    height: 40,
    display: "desktop",
  },
  {
    alt: "Air France",
    src: "/img/pages/landing/clientLogos/airfrance.svg",
    width: 250,
    height: 40,
    display: "desktop",
  },
] as const;

const Advertising = () => {
  const { t } = useTranslation("home");
  return (
    <>
      <AdvertisingTitleContainer>
        <AdvertisingTitleOne variant="hL">
          {t("home.advertisning.title")}
        </AdvertisingTitleOne>
      </AdvertisingTitleContainer>
      <AdvertisingStyledSubTitle variant="bodyXL" color="textSecondary">
        {t("home.advertisning.sub.title")}
      </AdvertisingStyledSubTitle>
      <AdvertiserLogosContainer>
        {ADVERTISER_LOGOS.map((logo, index) => (
          <AdvertiserImageContainer key={index} $display={logo.display}>
            <StyledImageAdvertising
              width={logo.width}
              height={logo.height}
              src={logo.src}
              alt={logo.alt}
              priority
            />
          </AdvertiserImageContainer>
        ))}
      </AdvertiserLogosContainer>
    </>
  );
};

export default Advertising;
